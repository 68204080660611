import {
  Box, ChakraProvider, Container, theme
} from "@chakra-ui/react"
import Contact from "./pages/Contact"
import Features from "./pages/Features"
import Hero from "./pages/Hero"
import Equipment from "./pages/Equipment"
import Photos from "./pages/Photos"

// Note: using templates from here:
// https://chakra-templates.dev/page-sections/hero

export const App = () => (
  <ChakraProvider theme={theme}>
    <Box height="20px" bg="red.600"></Box>
    <Box position="absolute" top="2rem" width="100%">
      {/* <Heading
        fontWeight={600}
        fontSize={{ base: '3xl', sm: '3xl', md: '3xl' }}
        lineHeight={'110%'}
        textAlign={{ base: 'center', sm: 'left' }}
        padding={{ base: '0', sm: '0 3rem' }}>
        Upstairs{' '}
        <Text as={'span'} color={'red.500'}>
          Downstairs
        </Text>
      </Heading> */}
    </Box>
    <Hero />
    <Features />
    <Equipment />
    <Photos />
    <Contact />
    {/* Testimonials */}
    <Box textAlign="center" mb="1rem" color="gray.500">
      © Upstairs Downstairs — Nationwide delivery and service covering UK &
      Ireland
</Box>
    <Box height="20px" bg="red.600"></Box>
  </ChakraProvider>
);
