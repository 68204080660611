import {
  Box, Button, Container, FormControl,
  FormLabel, HStack, Heading, Input,
  InputGroup,
  InputLeftElement, SimpleGrid, Text, Textarea, VStack
} from '@chakra-ui/react';
import { BsPerson } from 'react-icons/bs';
import {
  MdBusiness,
  MdEmail, MdPhone
} from 'react-icons/md';
import { email, phone, phoneLink } from './text';
import ContactForm from './ContactForm';

export default function Contact() {
  return (
    <Container maxW={'6xl'}>
      <Box
        bg="gray.100"
        color="black"
        borderRadius="lg"
        // m={{ sm: 4, md: 16, lg: 10 }}
        m={`4rem 0 6rem`}
        p={{ base: 2, md: 10 }}
      >
        <SimpleGrid
          columns={{ base: 1, md: 1 }}
          spacing={10}
          textAlign="center"
          alignItems="center"
        >
          <Box p={{ base: 10, md: 16 }}>
            <Heading>How can we help?</Heading>
            <Text
              mt={{ sm: 3, md: 3, lg: 5 }}
              color="gray.600"
              fontSize="lg"
              maxW="xl"
              margin="0 auto"
            >
              Whether you have questions about specific equipment, need a
              training session, or just a consultation. You can email or ring us
              for free.
              {/* or use the contact form. */}
            </Text>
            <SimpleGrid
              pt="2rem"
              gap={6}
              columns={{ base: 1, md: 2 }}
              maxWidth="xl"
              margin="0 auto"
            >
              <a href={`mailto:${email}`}>
                <Button
                  rounded={'full'}
                  px={6}
                  colorScheme={'orange'}
                  bg={'red.500'}
                  _hover={{ bg: 'red.500' }}
                  leftIcon={<MdEmail size="20px" />}
                  size="lg"
                >
                  {email}
                </Button>
              </a>
              <a href={`tel:${phoneLink}`}>
                <Button
                  rounded={'full'}
                  px={6}
                  bg={'gray.200'}
                  leftIcon={<MdPhone size="20px" />}
                  size="lg"
                >
                  {phone}
                </Button>
              </a>
            </SimpleGrid>
          </Box>

          {/* <Box bg="white" borderRadius="lg">
          <Box m={8} color="#0B0E3F">
            <ContactForm />
            <form name="contact" method="post" data-netlify="true" hidden>
              <input type="hidden" name="form-name" value="contact" />

              <VStack spacing={5}>
                <FormControl id="name">
                  <FormLabel>Your Name</FormLabel>
                  <InputGroup borderColor="#E0E1E7">
                    <InputLeftElement
                      pointerEvents="none"
                      children={<BsPerson color="gray.800" />}
                    />
                    <Input type="text" size="md" />
                  </InputGroup>
                </FormControl>
                <FormControl id="business-name">
                  <FormLabel>Business Name</FormLabel>
                  <InputGroup borderColor="#E0E1E7">
                    <InputLeftElement
                      pointerEvents="none"
                      children={<MdBusiness color="gray.800" />}
                    />
                    <Input type="text" size="md" />
                  </InputGroup>
                </FormControl>
                <FormControl id="email">
                  <FormLabel>Email</FormLabel>
                  <InputGroup borderColor="#E0E1E7">
                    <InputLeftElement
                      pointerEvents="none"
                      children={<MdEmail color="gray.800" />}
                    />
                    <Input type="email" size="md" />
                  </InputGroup>
                </FormControl>
                <FormControl id="message">
                  <FormLabel>What can we help you with?</FormLabel>
                  <Textarea
                    borderColor="gray.300"
                    _hover={{
                      borderRadius: 'gray.300',
                    }}
                    placeholder="Enter your message..."
                  />
                </FormControl>
                <FormControl id="name" float="right">
                  <Button
                    variant="solid"
                    bg="red.500"
                    color="white"
                    _hover={{}}
                  >
                    Send Message
                  </Button>
                </FormControl>
              </VStack>
            </form>
          </Box>
        </Box> */}
        </SimpleGrid>
      </Box>
    </Container>
  );
}