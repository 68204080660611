import { Box, Container, Flex, Heading, Icon, SimpleGrid, Stack, Text } from '@chakra-ui/react';
import { ReactElement } from 'react';
import { FcAdvance, FcGraduationCap, FcInTransit, FcReading, FcRefresh, FcShipped, FcSupport } from 'react-icons/fc';

import photo1 from '../images/photo1.webp'
import photo2 from '../images/photo2.webp'
import photo3 from '../images/photo3.webp'
import photo4 from '../images/photo4.webp'

export default function Photos() {
  return (
    <Box p={4} bg="gray.50" pt="20" pb="20">
      <Container maxW={'6xl'}>
        <SimpleGrid columns={{ base: 2, md: 4 }} spacing={10}>
          <img src={photo1} alt="" />
          <img src={photo2} alt="" />
          <img src={photo3} alt="" />
          <img src={photo4} alt="" />
        </SimpleGrid>
      </Container>
    </Box>
  );
}