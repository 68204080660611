import { Box, Container, Flex, Heading, SimpleGrid, Stack, Text } from '@chakra-ui/react';
import { ReactElement } from 'react';
import equipment from '../images/equipment.webp';

interface EquipmentProps {
  title: string;
  text: string;
  icon: ReactElement;
}

// const EquipmentItem = ({ title, text, icon }: EquipmentProps) => {
//   return (
//     <Stack>
//       <Flex
//         w={16}
//         h={16}
//         align={'center'}
//         justify={'center'}
//         color={'white'}
//         rounded={'full'}
//         bg={'gray.100'}
//         mb={1}
//         >

//         {icon}
//       </Flex>
//       <Text fontWeight={600}>{title}</Text>
//       <Text color={'gray.600'}>{text}</Text>
//     </Stack>
//   );
// };

export default function Equipment() {
  return (
    <Box p={4} bg="white" pt="20" pb="20">
      <Container maxW={'6xl'}>
        <Heading size="lg" pb="8" textAlign="center">
          Examples of the equipment we use
        </Heading>
          <Box maxW="3xl" margin="0 auto">
            <img src={equipment} alt="Equipment examples" />
          </Box>
      </Container>
    </Box>
  );
}