import { Box, Container, Flex, Heading, Icon, SimpleGrid, Stack, Text } from '@chakra-ui/react';
import { ReactElement } from 'react';
import { FcAdvance, FcGraduationCap, FcInTransit, FcReading, FcRefresh, FcShipped, FcSupport } from 'react-icons/fc';

interface FeatureProps {
  title: string;
  text: string;
  icon: ReactElement;
}

const Feature = ({ title, text, icon }: FeatureProps) => {
  return (
    <Stack>
      <Flex
        w={16}
        h={16}
        align={'center'}
        justify={'center'}
        color={'white'}
        rounded={'full'}
        bg={'gray.100'}
        mb={1}
        >

        {icon}
      </Flex>
      <Text fontWeight={600}>{title}</Text>
      <Text color={'gray.600'}>{text}</Text>
    </Stack>
  );
};

export default function Features() {
  return (
    <Box p={4} bg="gray.50" pt="20" pb="20">
      <Container maxW={'6xl'}>
        <Heading size="lg" pb="8" textAlign="center">
          Our services
        </Heading>
        <SimpleGrid columns={{ base: 1, md: 4 }} spacing={10}>
          <Feature
            icon={<Icon as={FcShipped} w={10} h={10} />}
            title={'Supply'}
            text={
              'Supplier of all types of evacuation chairs and stair climbers. New and used.'
            }
          />
          <Feature
            icon={<Icon as={FcReading} w={10} h={10} />}
            title={'Training'}
            text={
              'Expert onsite operator training on all makes and models of powered stair climbers and evacution chairs, for 2-5 people.'
            }
          />
          <Feature
            icon={<Icon as={FcGraduationCap} w={10} h={10} />}
            title={'Train the trainer'}
            text={
              'Our key trainer course, is onsite for 2-4 people that "trains the trainer" to enable them to train other colleagues successfully.'
            }
          />
          <Feature
            icon={<Icon as={FcSupport} w={10} h={10} />}
            title={'Service'}
            text={
              'Maintenance and repairs of all makes and models of evacuation chairs and stairclimbers.'
            }
          />
          <Feature
            icon={<Icon as={FcRefresh} w={10} h={10} />}
            title={'Hire / rental'}
            text={'Solutions for when your lifts or equipment break down. Events, shows or weddings.'}
          />
          <Feature
            icon={<Icon as={FcInTransit} w={10} h={10} />}
            title={'Solutions'}
            text={
              'We can offer a choice of solutions for your stairs and landing requirements.'
            }
          />
          <Feature
            icon={<Icon as={FcAdvance} w={10} h={10} />}
            title={'Exchange & upgrade'}
            text={'We part exchange, upgrade, and buy pre-owned equipment.'}
          />
        </SimpleGrid>
      </Container>
    </Box>
  );
}